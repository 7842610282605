.error {
  color: #df4759;
  font-size: 80%;
}
.toast {
  margin-top: 20px;
}
.p-3 {
  width: 300px;
}
